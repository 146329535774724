<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('tpm_report.allowance_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
              <b-row>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="search.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="6">
                  <ValidationProvider name="Circular Memo No"  vid="circular_memo_no" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="search.circular_memo_no"
                        :options="circularList"
                        id="circular_memo_no"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option value="">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col>
              </b-row>
              <b-overlay :show="loading">
                <b-row>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Organization" vid="org_id" >
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="orgList"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                  <b-col lg="6" sm="6">
                    <ValidationProvider name="Training Title" vid="training_title_id">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_title_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_title')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_title_id"
                          :options="trainingTitleList"
                          id="training_title_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="6">
                    <ValidationProvider name="Batch" vid="batch_id">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="batch_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.batch')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.batch_id"
                          :options="batchList"
                          id="batch_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-overlay>
              <div class="row">
                <b-col sm="9">
                </b-col>
                <b-col sm="3" style="text-align: right;">
                  <b-button size="sm" type="submit" variant="primary" class="mt-20">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
              </div>
            </b-form>
          </ValidationObserver>
    </template>
      <!-- search section end -->
    </card>
    <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay>
            <b-row>
              <b-col md="12">
                <body-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">
                    {{ $t('tpm_report.allowance_report') }} {{ $t('globalTrans.list') }}
                  </h4>
                </template>
                <template v-slot:headerAction>
                  <b-button v-if="finalList.length === undefined" variant="primary" size="sm" @click="pdfExport" class="float-right">
                      {{  $t('globalTrans.export_pdf') }}
                  </b-button>
                </template>
                  <template v-slot:body>
                    <b-overlay :show="customloading">
                      <list-report-head :baseUrl="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="search.org_id ? search.org_id : 12">
                        <template v-slot:projectNameSlot>
                          {{ }}
                        </template>
                        {{ $t('tpm_report.trainee_allowance_report') }}
                      </list-report-head>
                      <b-row>
                        <b-col md="4">
                          <span>{{ $t('globalTrans.fiscal_year') }}</span>:
                          <strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchHeaderData.fiscal_year_en : searchHeaderData.fiscal_year_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="4" class="text-center">
                          <span>{{ $t('elearning_iabm.circular_memo_no') }}</span>:
                          <strong>{{ search.circular_memo_no ? ($i18n.locale === 'en' ? searchHeaderData.circular_memo_no_en : searchHeaderData.circular_memo_no_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="4" class="text-right">
                          <span>{{ $t('elearning_config.batch') }}</span>:
                          <strong>{{ search.batch_id ? ($i18n.locale === 'en' ? searchHeaderData.batch_en : searchHeaderData.batch_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12">
                          <table style="width:100%;">
                            <thead>
                              <tr>
                                <th>{{ $t('globalTrans.sl_no') }}</th>
                                <th>{{ $t('globalTrans.name') }}</th>
                                <th>{{ $t('globalTrans.designation') }}</th>
                                <th>{{ $t('elearning_tim.number_of_class') }}</th>
                                <th>{{ $t('elearning_tim.allowance') }}</th>
                                <th>{{ $t('globalTrans.total_amount') }}</th>
                                <th>{{ $t('globalTrans.income_tax') }}</th>
                                <th>{{ $t('globalTrans.income_tax_amount') }}</th>
                                <th>{{ $t('globalTrans.other_detuction') }}</th>
                                <th>{{ $t('globalTrans.net_amount') }}</th>
                              </tr>
                            </thead>
                            <tbody v-if="finalList.length === undefined">
                              <span hidden>{{ $i = 1 }} {{ $si = 1 }}</span>
                              <template v-for="(items, index) in finalList">
                                <tr style="background: #ddd;" :key="index">
                                  <td colspan="10" class="text-left"> <span class="mr-3">{{ $n($i++) + '.' }}</span>{{ getBatch(index) }} </td>
                                </tr>
                                  <tr v-for="(item, index2) in items" :key="index2">
                                    <td class="text-center"> {{$n($si++)}}</td>
                                    <td> {{ currentLocale === 'bn' ? item.personal.name_bn : item.personal.name }}</td>
                                    <td> {{ ($i18n.locale==='bn') ? item.personal.professional_info.designation_bn : item.personal.professional_info.designation_en }} </td>
                                    <td> {{ $n(item.total_days) }} </td>
                                    <td> {{ $n(item.honour_amount) }} </td>
                                    <td> {{$n(item.total_days * item.honour_amount)}} </td>
                                    <td> {{$n(item.vat_tax)}} </td>
                                    <td> {{$n(item.vat_tax_amount)}}</td>
                                    <td> {{ $n(item.other) }}</td>
                                    <td>{{ $n(item.payment_amount) }}</td>
                                  </tr>
                              </template>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td colspan="10" class="text-center"> {{ $t('globalTrans.noDataFound') }} </td>
                              </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </body-card>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
      </b-row>
  </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularMemoList, circularData, billPaymentReport } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
// import moment from 'moment'
// const today = new Date().toISOString().substr(0, 10)
export default {
  mixins: [ModalBaseMasterList],
  components: {
    ListReportHead
  },
  data () {
    return {
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      sortBy: '',
      customloading: false,
      isResult: false,
      finalList: [],
      circularList: [],
      trainingCategoryList: [],
      batchList: [],
      trainingTitleList: [],
      // search: {
      //   payment_type: 'Trainee',
      //   fiscal_year_id: [],
      //   org_id: [],
      //   training_type_id: 0,
      //   training_title_id: 0,
      //   batch_id: 0,
      //   circular_memo_no: 0
      // },
      search: {
        payment_type: 'Trainee',
        fiscal_year_id: 0,
        org_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        batch_id: 0,
        circular_memo_no: ''
      },
      searchHeaderData: {
        fiscal_year_en: '',
        fiscal_year_bn: '',
        org_en: '',
        org_bn: '',
        circular_memo_no: '',
        training_type_en: '',
        training_type_bn: '',
        training_title_en: '',
        training_title_bn: '',
        batch_en: '',
        batch_bn: ''
      },
      showData: false,
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '9%' } },
        { labels: 'fair_report.name_title_event', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
        { labels: 'fair_report.city_country', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '20%' } },
        { labels: 'externalTradeFair.duration', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '20%' } },
        { labels: 'externalTradeFair.product_service_exhibited', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '20%' } },
        { labels: 'externalTradeFair.proposed_by', class: 'text-center', show: '1', order: 6 }
      ]
    }
  },
  created () {
    this.search.fiscal_year_id = this.$store.state.currentFiscalYearId
    // this.loadData()
    this.getCircularList()
  },
  watch: {
    'search.org_id': function (newValue) {
      this.batchList = this.getBatchList(newValue)
    },
    'search.fiscal_year_id': function (newValue) {
      this.getCircularList()
    },
    'search.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'search.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    fairSectorList () {
      return this.$store.state.TradeFairService.commonObj.fairSectorList
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(el => el.status === 1)
    },
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'fair_name_bn' },
          { key: 'country_id' },
          { key: 'duration' },
          { key: 'product_service_exhibited_bn' },
          { key: 'proposed_by_bn' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'fair_name' },
          { key: 'country_id' },
          { key: 'duration' },
          { key: 'product_service_exhibited' },
          { key: 'proposed_by' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    getBatch (id) {
      const sectorObj = this.$store.state.TrainingElearning.commonObj.batchList.find(el => el.value === parseInt(id))
      if (sectorObj !== undefined) {
        return this.currentLocale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
      }
    },
    getBatchList (orgId) {
      const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
      if (orgId) {
        return batchList.filter(item => item.org_id === orgId)
      }
      return batchList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.search.fiscal_year_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularMemoList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
         const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_type_id === categoryId)
      }
      return trainingTitleList
    },
    async getCircularMemoNo (getCircularMemoNo) {
      this.load = true
      const params = {
        circular_memo_no: getCircularMemoNo,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularData, params)
      if (!result.success) {
        this.search.org_id = 0
        this.search.training_title_id = 0
        this.search.training_type_id = 0
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.search.org_id = trData.org_id
        this.search.training_title_id = trData.training_title_id
        this.search.training_type_id = trData.training_type_id
        this.load = false
      }
      this.dateList = []
      this.load = false
    },
    async searchData () {
        this.customloading = true
        this.showData = true
        if (this.search.fiscal_year_id) {
          const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
          const customItem = {
            fiscal_year_en: fiscalYear?.text_en,
            fiscal_year_bn: fiscalYear?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
        }
        if (this.search.circular_memo_no) {
          const circularObj = this.circularList.find(obj => obj.value === this.search.circular_memo_no)
          const circularData = {
            circular_memo_no_en: circularObj?.text,
            circular_memo_no_bn: circularObj?.text
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, circularData)
        }
        if (this.search.batch_id) {
          const batchObj = this.batchList.find(obj => obj.value === this.search.batch_id)
          const batchData = {
            batch_en: batchObj?.text_en,
            batch_bn: batchObj?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, batchData)
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, billPaymentReport, this.search)
        if (result.success) {
          this.customloading = false
          if (result.data) {
            this.finalList = result.data
          }
        } else {
          this.finalList = []
          this.customloading = false
        }
      },
    async pdfExport () {
      this.customloading = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, fiscal_year_id: this.search.fiscal_year_id, circular_memo_no: this.search.circular_memo_no, batch_id: this.search.batch_id, payment_type: this.search.payment_type, org_id: this.search.org_id, training_type_id: this.search.training_type_id, training_title_id: this.search.training_title_id })
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, billPaymentReport, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.customloading = false
    }
  }
}
</script>
<style scoped>
  table, th, td {
    border: 1px solid black;
    padding:5px;
  }
</style>
